import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("defs", null, [
      _createElementVNode("linearGradient", {
        id: "TelusGradient",
        x1: "0",
        x2: "1",
        y1: "0",
        y2: "1"
      }, [
        _createElementVNode("stop", {
          offset: "0%",
          "stop-color": "#4B286D"
        }),
        _createElementVNode("stop", {
          offset: "100%",
          "stop-color": "#E53293"
        })
      ])
    ], -1),
    _createElementVNode("path", { d: "M20.125 7H3.875A1.745 1.745 0 0 0 2.13 8.87l.535 7.725C2.95 20.67 5.05 22.49 7 22.715c3.322.38 6.678.38 10 0 1.95-.225 4.05-2.045 4.335-6.12l.535-7.725A1.746 1.746 0 0 0 20.125 7ZM8.5 5.5H7a5 5 0 0 1 10 0h-1.5a3.5 3.5 0 0 0-7 0Z" }, null, -1)
  ])))
}
export default { render: render }